import { Container, Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../components/PageHeader";
import { withAuthorization } from "../components/Session";
import SourcesCard from "../components/SourceCard";

const SourcesPage = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Grid container>
        <Grid item xs={12} lg={9}>
          <PageHeader />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: 24 }}>
        {src.map((s) => (
          <Grid item xs={12} sm={6} xl={3} container>
            <SourcesCard source={s} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(SourcesPage);

const src = [
  {
    title: "Reanibex USB SYNC",
    url: "",
    type: "app",
    link: "https://usbsync.blob.core.windows.net/app/REANIBEX_USB_SYNC.exe",
    has_link: false,
    subtitle: "source.rdn.subtitle",
  },
  {
    title: "Reanibex NFC Reader",
    url: "",
    type: "app",
    link: "https://play.google.com/store/apps/details?id=com.bexencardio.conf_nfc2&pcampaignid=web_share",
    has_link: false,
    subtitle: "source.nfc.subtitle",
  },
  {
    title: "Reanibex Alert",
    url: "",
    type: "app",
    link: "https://play.google.com/store/apps/details?id=com.reanibex_alert&gl=ES",
    has_link: true,
    subtitle: "source.alert.subtitle",
    buttons: [
      { url: "https://apps.apple.com/es/app/reanibex-alert/id6445820800", type: "ios" },
      { url: "https://play.google.com/store/apps/details?id=com.reanibex_alert&gl=ES", type: "android" },
    ],
  },
  {
    title: "Reanibex training",
    url: "",
    type: "web",
    link: "https://reanibex-training.web.app/",
    has_link: true,
    subtitle: "source.training.subtitle",
  },
  {
    title: "Release Note",
    url: "https://epirepository.blob.core.windows.net/imgs/Release_Note_REANIBEX_100_v2_10_ENG.pdf",
    type: "app",
    has_link: true,
    link: "https://epirepository.blob.core.windows.net/imgs/Release_Note_REANIBEX_100_v2_10_ENG.pdf",
    subtitle: "source.release.subtitle",
  },
];