import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as DATANETAUTH from "../DataNet/DataNetAuth";

import { Button, Snackbar, Grid } from "@material-ui/core";
import { CancelOutlined, CheckCircleOutlineOutlined } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { green, red } from "@material-ui/core/colors";
import ButtonLoader from "../ButtonLoader";

const RequestPasswordButton = ({ reusable, email }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordResetCorrect, setIsPasswordResetCorrect] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);

  /** Solicitar el cambio de contraseña */
  /** de momento esta con u aleatorio para sacar todas las opciones */
  const handlePassworChange = () => {
    setIsLoading(true);
    DATANETAUTH.sendPasswordResetEmail(email)
      .then((snap) => {
        //    console.log(snap);
        //  console.log("succes");
        showsnackBar(snap.status == 1);
      })
      .catch((err) => {
        //     console.log("error", err);
        showsnackBar(false);
      });
  };

  const showsnackBar = (isCorrect) => {
    if (isCorrect) {
      setOpenSnackBar(true);
      setIsPasswordResetCorrect(true);
      setIsLoading(false);
      setIsRequestSent(true);
    } else {
      setOpenSnackBar(true);
      setIsPasswordResetCorrect(false);
      setIsLoading(false);
      setIsRequestSent(true);
    }
  };

  if (!reusable && isRequestSent) {
    return (
      <>
        {isPasswordResetCorrect ? <CheckCircleOutlineOutlined style={{ color: green[500] }} /> : <CancelOutlined style={{ color: red[500] }} />}
        <Snack openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} isPasswordResetCorrect={isPasswordResetCorrect} t={t} />
      </>
    );
  } else {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
          onClick={() => handlePassworChange()}
          disabled={!email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i) || isLoading}
        >
          {t("requestPasswordChange")}
          {isLoading && <ButtonLoader />}
        </Button>

        <Snack openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} isPasswordResetCorrect={isPasswordResetCorrect} t={t} />
      </>
    );
  }
};

export default RequestPasswordButton;

const Snack = ({ openSnackBar, setOpenSnackBar, isPasswordResetCorrect, t }) => (
  <Snackbar
    open={openSnackBar}
    autoHideDuration={6000}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    onClose={() => {
      setOpenSnackBar(false);
    }}
  >
    <Alert
      onClose={() => {
        setOpenSnackBar(false);
      }}
      severity={isPasswordResetCorrect ? "success" : "error"}
    >
      {t(isPasswordResetCorrect ? "checkEmail" : "checkEmailError")}
    </Alert>
  </Snackbar>
);
