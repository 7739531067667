import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Hidden,
  Typography,
  Fab,
  makeStyles,
  Zoom,
  Badge,
  Chip,
} from "@material-ui/core";
import { green, orange, red, grey, purple, blueGrey } from "@material-ui/core/colors";
import { Add, Check, LocationOn, Search, Wifi, WifiOff, Error, Settings, NewReleases, Description, FlashOn, Warning, SyncProblem, Event, RemoveRedEye, InfoOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AddDevice from "../components/AddDevice";
import MapDialog from "../components/MapDialog";
import PageHeader from "../components/PageHeader";
import * as ROUTES from "../constants/routes";
import { useTranslation } from "react-i18next";
import DeviceSetupForm from "../components/DeviceSetupForm";
import { withAuthorization } from "../components/Session";
import * as DATANETAPI from "../components/DataNet/DataNet";
import DeviceStatusModal from "../components/DeviceStatusModal";
import { AuthUserContext } from "../components/Session";
import DeviceUseEventModal from "../components/DeviceUseEventModal";
import { set } from "lodash";
import ErrorDialog from "../components/ErrorDialog";
import moment from "moment";
import getDeviceStatus from "../functions/getDeviceStatus";
import Pads_Icon from "../assets/images/Parches_2.png";
import { Skeleton } from "@material-ui/lab";
import batteryRemainingCalc from "../functions/BatteryChargeCalc";
import SigfoxLogo from "../assets/images/sigfox-logo-vector.png";
import getDeviceComunications from "../functions/getDeviceComunications";

/* valores de periodos para dar mensaje de error de conexión */
const TRASNMISSION_FREC = {
  0: { gap: 3, type: "d" },
  1: { gap: 2, type: "w" },
  2: { gap: 1, type: "M" },
};

const DevicesPage = () => {
  const history = useHistory();
  const { programId, groupId } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();

  const [isMapOpen, setisMapOpen] = useState(false);
  const [deviceLocation, setDeviceLocation] = useState({});
  const [devices, setDevices] = useState([]);
  const [areDevicesLoaded, setAreDevicesLoaded] = useState(false);
  const [isFiltered, setisFiltered] = useState(false);
  const [addNewOpen, setaddNewOpen] = useState(false);
  const [deviceSetupOpen, setdeviceSetupOpen] = useState(false);
  const [isNewVersion, setIsNewVersion] = useState(false);
  const [isStatusModalOpen, setisStatusModalOpen] = useState(false);
  const [deviceStatus, setDeviceStatus] = useState({});
  const [useEventModalOpen, setisUseEventModalOpen] = useState(false);
  const [usageNotifications, setUsageNotifications] = useState({});
  const [notificationsHistory, setNotificationsHistory] = useState([]);
  const [isNotificationsHisLoaded, setIsNotificationHisLoaded] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [newInterval, setNewInterval] = useState(0);
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);

  const filterDevices = (event_value, list_devices) => {
    let filteredDevices = [];
    list_devices.forEach((device) => {
      if (device.name.toUpperCase().concat(device.serial.toUpperCase()).concat(device.model.toUpperCase()).includes(event_value.toUpperCase())) {
        filteredDevices.push(device);
      }
    });
    setDevices(filteredDevices);
  };

  useEffect(() => {
    loadDevices();
    loadNotificationHistory();
  }, [newInterval]);

  useEffect(() => {
    filterValue !== "" && setisFiltered(true);
    filterDevices(filterValue, devicesBack);
  }, [filterValue]);

  const displayMap = (location) => {
    setDeviceLocation(location);
    setisMapOpen(true);
  };

  const loadDevices = () => {
    setAreDevicesLoaded(false);
    if (!addNewOpen) {
      DATANETAPI.getDevices(programId, groupId).then((snap) => {
        if (snap.status >= 0) {
          if (snap.data !== undefined) {
            if ((devicesBack.length !== 0 && snap.data.length !== 0) || devicesBack.length === 0) {
              devicesBack = snap.data;
            }

            devicesBack.forEach((dev) => {
              if (dev.status.main !== 2 && dev.model === "Reanibex 100") {
                let statusCount = 0;
                Object.keys(dev.status.update).map((u) => {
                  statusCount += dev.status.update[u];
                });
                if (statusCount > 0) {
                  dev.status.main = 1;
                }
              }
            });
            filterDevices(filterValue, devicesBack);
            setAreDevicesLoaded(true);
            setIsInitialLoadDone(true);
          } else {
            setDevices([]);
            setAreDevicesLoaded(true);
            setIsInitialLoadDone(true);
          }
        } else {
          setConnectionErrorMsg(t(`error.connection_${snap.status}`));
          setDevices([]);
          setAreDevicesLoaded(true);
          setIsInitialLoadDone(true);
        }
      });
    }
  };

  const loadNotificationHistory = () => {
    DATANETAPI.getNotificationHistory().then((snap) => {
      if (snap !== undefined && snap.data !== null && snap.data !== undefined && snap.data.history !== undefined && snap.data.history.notifications !== undefined) {
        if (snap.data.history.notifications.length > 0) {
          setNotificationsHistory(snap.data.history.notifications);
        } else {
          setNotificationsHistory([]);
        }
        setIsNotificationHisLoaded(true);
      } else {
        snap?.status < 0 && setConnectionErrorMsg(t(`error.connection_${snap.status}`));
        setIsNotificationHisLoaded(true);
        setNotificationsHistory([]);
      }
    });
  };

  useEffect(() => {
    let reaload = setInterval(() => {
      setNewInterval((prev) => !prev);
    }, 40 * 1000);

    return () => {
      clearTimeout(reaload);
    };
  }, []);

  useEffect(() => {
    setDevices([]);
    devicesBack = [];
    loadDevices();
    loadNotificationHistory();
  }, [groupId, programId, addNewOpen]);

  useEffect(() => {
    DATANETAPI.newVersionGroup(groupId).then((snap) => {
      if (snap?.status < 0) {
        setConnectionErrorMsg(t(`error.connection_${snap.status}`));
        setIsNewVersion(false);
      } else {
        if (snap?.status === 1) {
          setIsNewVersion(snap.data.is_available);
        } else {
          setIsNewVersion(false);
        }
      }
    });
  }, [deviceSetupOpen, programId, groupId]);

  return (
    <Container>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <PageHeader title={t("common.devices")} />
        </Grid>
        <Grid item>
          <Paper style={{ padding: 12 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item container alignItems="center" xs={6} justify="flex-start">
                <Grid item>
                  <TextField
                    label={t("common.search")}
                    onChange={(e) => {
                      setFilterValue(e.target.value);
                    }}
                    margin="dense"
                    placeholder={t("common.search")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                {(devices.some((d) => d.model === "Reanibex 100") || isNewVersion) && (
                  <Grid item>
                    <Tooltip
                      placement="top"
                      enterDelay={750}
                      title={
                        !!!groupId ? (
                          <Typography variant="body1" style={{ fontSize: 12 }}>
                            {t("devices.toolbar.tooltip.settingsDisabled")}
                          </Typography>
                        ) : (
                          <Typography variant="body1" style={{ fontSize: 12 }}>
                            {isNewVersion ? t("devices.setUp.info.update_opt") : t("devices.toolbar.tooltip.settingsEnabled")}
                          </Typography>
                        )
                      }
                    >
                      <span>
                        <IconButton arial-label={t("devices.setUp.info")} disabled={!!!groupId} style={{ color: isNewVersion && orange[500] }} onClick={() => setdeviceSetupOpen(true)}>
                          {isNewVersion ? <NewReleases /> : <Settings />}
                        </IconButton>
                      </span>
                    </Tooltip>
                    <DeviceSetupForm
                      open={deviceSetupOpen}
                      handleClose={setdeviceSetupOpen}
                      groupId={groupId} // siempre deberia de haber un grupo
                    />
                  </Grid>
                )}

                <Grid item>
                  <Tooltip
                    placement="top"
                    title={
                      <Typography variant="body1" style={{ fontSize: 12 }}>
                        {t("devices.toolbar.tooltip.addDevice")}
                      </Typography>
                    }
                  >
                    <IconButton aria-label="add" onClick={() => setaddNewOpen(true)}>
                      <Add />
                    </IconButton>
                  </Tooltip>
                  {<AuthUserContext.Consumer>{(authUser) => <AddDevice open={addNewOpen} handleClose={setaddNewOpen} authUser={authUser} />}</AuthUserContext.Consumer>}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item className={classes.table}>
          <TableContainer component={Paper} variant="outlined">
            {!areDevicesLoaded && <LinearProgress />}
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t("common.state")}</TableCell>
                  <Hidden smDown>
                    <TableCell>{t("common.name")}</TableCell>
                  </Hidden>
                  <TableCell align="center">{t("common.serialNumber")}</TableCell>

                  <Hidden smDown>
                    <TableCell align="center">{t("common.model")}</TableCell>
                  </Hidden>
                  <Hidden xsDown>
                    <TableCell align="center">{t("common.software")}</TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell align="center">{t("common.usage")}</TableCell>
                  </Hidden>

                  <Hidden xsDown>
                    <TableCell align="center">{t("common.location")}</TableCell>
                  </Hidden>
                  <Hidden xsDown>
                    <TableCell align="center">{t("common.communications")}</TableCell>
                  </Hidden>
                  <Hidden xsDown>
                    <TableCell align="center">{t("common.connected")}</TableCell>
                  </Hidden>
                  <TableCell align="center">{t("description")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isInitialLoadDone
                  ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => <LoadingRows ind={i} />)
                  : devices.map((row, index) => (
                      <TableRow
                        hover
                        key={row.serial}
                        style={{
                          background: row?.status?.is_demo
                            ? orange[50]
                            : row.model === "Reanibex 100"
                            ? getDeviceStatus(
                                row?.status,
                                moment().diff(row?.status?.updateDate, "months") >= (row?.connectivity ? 3 : 12),
                                batteryRemainingCalc({
                                  software: row?.soft_version,
                                  syncDate: moment(row?.status?.updateDate),
                                  battery: row?.status?.device_status?.batteryStatus,
                                  updateSource: row?.status?.device_status?.updateSource,
                                })
                              ).statusResult > 0 && red[50]
                            : (moment(row?.expirationDatePads).isBefore(moment().add(90, "days")) || moment(row?.expirationDateBatt).isBefore(moment().add(90, "days"))) && red[50],
                        }}
                      >
                        <TableCell align="center">
                          {row.model === "Reanibex 100" ? (
                            <IconButton
                              onClick={() => {
                                setDeviceStatus(row);
                                setisStatusModalOpen(true);
                              }}
                            >
                              <StatusIcon
                                row={row}
                                battery={batteryRemainingCalc({
                                  software: row?.soft_version,
                                  syncDate: moment(row?.status?.updateDate),
                                  battery: row?.status?.device_status?.batteryStatus,
                                  updateSource: row?.status?.device_status?.updateSource,
                                })}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                setDeviceStatus(row);
                                setisStatusModalOpen(true);
                              }}
                            >
                              {moment(row?.expirationDatePads).isBefore(moment()) || moment(row?.expirationDateBatt).isBefore(moment()) ? (
                                <Error style={{ color: red[400] }} />
                              ) : moment(row?.expirationDatePads).isBefore(moment().add(90, "days")) || moment(row?.expirationDateBatt).isBefore(moment().add(90, "days")) ? (
                                <Warning style={{ color: orange[400] }} />
                              ) : (
                                <Check style={{ color: green[300] }} />
                              )}
                            </IconButton>
                          )}
                        </TableCell>
                        <Hidden smDown>
                          <TableCell component="th" scope="row" style={{ fontWeight: 500 }}>
                            {row?.status?.is_demo && (
                              <Typography variant="body1" style={{ fontWeight: 600, color: orange[500] }}>
                                DEMO
                              </Typography>
                            )}
                            {` ${row.name}`}
                          </TableCell>
                        </Hidden>

                        <TableCell align="center">{row.serial}</TableCell>

                        <Hidden smDown>
                          <TableCell align="center">{row.model}</TableCell>
                        </Hidden>

                        <Hidden xsDown>
                          <TableCell align="center">
                            {!row.soft_version ? (
                              <Typography>-</Typography>
                            ) : (
                              <>
                                {!row.status ? (
                                  <Typography>-</Typography>
                                ) : !row.status.hasOwnProperty("update") ? (
                                  <>{row.soft_version}</>
                                ) : (
                                  <>
                                    {row?.status.update?.has_new_audio || row.status.update?.has_new_cfg || row?.status.update?.has_new_soft ? (
                                      <Tooltip title={t("common.pending_update")}>
                                        <IconButton
                                          onClick={() => {
                                            setDeviceStatus(row);
                                            setisStatusModalOpen(true);
                                          }}
                                        >
                                          <SyncProblem style={{ color: orange[300] }} />
                                          <Typography variant="body2" style={{ color: grey[900] }}>
                                            &nbsp;{row.soft_version}&nbsp;
                                          </Typography>
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <>{row.soft_version}</>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </TableCell>
                          <Hidden smDown>
                            <TableCell align="center">
                              {row.model === "Reanibex 100" && row.soft_version !== undefined && row.soft_version > "0206" ? (
                                <AuthUserContext.Consumer>
                                  {(authUser) => (
                                    <>
                                      {!authUser.active_notifications || !authUser.active_notifications.hasOwnProperty("notification") ? (
                                        <IconButton
                                          style={{ padding: 0 }}
                                          onClick={() => {
                                            setDeviceStatus(row);
                                            setisStatusModalOpen(true);
                                          }}
                                        >
                                          <FlashOn />
                                        </IconButton>
                                      ) : (
                                        <>
                                          {authUser.active_notifications.notification.some((x) => x.device_info.serial == row.serial && x.type == 1) ? (
                                            <IconButton
                                              style={{ padding: 0 }}
                                              onClick={() => {
                                                setDeviceStatus(row);
                                                setisUseEventModalOpen(true);
                                                setUsageNotifications(authUser.active_notifications.notification.filter((x) => x.device_info.serial == row.serial && x.type == 1));
                                              }}
                                            >
                                              <Badge color="error" variant="dot" showZero>
                                                <FlashOn />
                                              </Badge>
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              style={{ padding: 0 }}
                                              onClick={() => {
                                                setDeviceStatus(row);
                                                setisUseEventModalOpen(true);
                                                setUsageNotifications([]);
                                              }}
                                            >
                                              <FlashOn />
                                            </IconButton>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </AuthUserContext.Consumer>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          </Hidden>
                        </Hidden>

                        <Hidden xsDown>
                          <TableCell align="center">
                            <IconButton onClick={() => displayMap(row.location)}>
                              <LocationOn />
                            </IconButton>
                          </TableCell>
                        </Hidden>
                        <Hidden xsDown>
                          <TableCell align="center">
                            {/*  <img src={SigfoxLogo} width={64} />*/}

                            {row.model === "Reanibex 100" ? (
                              getDeviceComunications(row.options).map((v, i) => <Chip label={v} key={i} variant="default" size="small" color="secondary" style={{ borderRadius: 9, marginRight: 6 }} />)
                            ) : row.unabiz_alert ? (
                              <>
                                <Tooltip title={<Typography variant="body2">{t("unabiz.standalone.desc")}</Typography>} placement="top">
                                  <Chip
                                    label={"Unabiz"}
                                    key={row.id}
                                    variant="outlined"
                                    icon={<InfoOutlined />}
                                    onClick={() => {}}
                                    size="small"
                                    color="secondary"
                                    style={{ borderRadius: 9, marginRight: 6 }}
                                  />
                                </Tooltip>
                              </>
                            ) : (
                              t("not_available")
                            )}
                          </TableCell>
                        </Hidden>
                        <Hidden xsDown>
                          <TableCell align="center">
                            <TransmissionCheckItem deviceData={row} />
                          </TableCell>
                        </Hidden>

                        <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              history.push(ROUTES.DEVICE + "/" + row.id);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <Description />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {devices.length === 0 && areDevicesLoaded && (
              <Grid container justify="center" alignContent="center" style={{ height: 120 }}>
                <Typography variant="h4" color="textSecondary">
                  {t("devices.notFound")}
                </Typography>
              </Grid>
            )}
          </TableContainer>

          <MapDialog isOpen={isMapOpen} setisMapOpen={setisMapOpen} location={deviceLocation} />
          <DeviceStatusModal isStatusModalOpen={isStatusModalOpen} setisStatusModalOpen={setisStatusModalOpen} device={deviceStatus} />
          <DeviceUseEventModal
            isUseEventModalOpen={useEventModalOpen}
            setisUseEventModalOpen={setisUseEventModalOpen}
            currentNotifications={usageNotifications}
            notifications={notificationsHistory.filter((x) => x.device_info.serial === deviceStatus.serial && x.type === 1)}
            isNotificationsLoaded={isNotificationsHisLoaded}
            serial={deviceStatus.serial}
          />
        </Grid>
      </Grid>
      <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />
      <Zoom in={devices.length > 0 && devices.some((d) => d.model === "Reanibex 100") && !!groupId} timeout={250} unmountOnExit>
        <Fab variant="extended" color="primary" className={classes.Fab} onClick={() => setdeviceSetupOpen(true)}>
          <Settings style={{ marginRight: 6 }} />
          {t("group.r100Conf.button")}
        </Fab>
      </Zoom>
    </Container>
  );
};

const LoadingRows = ({ ind }) => (
  <TableRow hover key={ind}>
    <TableCell align="center">
      <Skeleton variant="circle" width={32} height={32} style={{ padding: 4 }} />
    </TableCell>
    <TableCell align="center">
      <Skeleton variant="text" />
    </TableCell>
    <Hidden smDown>
      <TableCell align="center">
        <Skeleton variant="text" />
      </TableCell>
    </Hidden>
    <Hidden smDown>
      <TableCell align="center">
        <Skeleton variant="text" />
      </TableCell>
    </Hidden>
    <Hidden xsDown>
      <TableCell align="center">
        <Skeleton variant="text" />
      </TableCell>
      <Hidden smDown>
        <TableCell align="center">
          <Skeleton variant="circle" width={32} height={32} style={{ padding: 4 }} />
        </TableCell>
      </Hidden>
    </Hidden>
    <Hidden smDown>
      <TableCell align="center">
        <Skeleton variant="text" />
      </TableCell>
    </Hidden>
    <Hidden xsDown>
      <TableCell align="center">
        <Skeleton variant="circle" width={32} height={32} style={{ padding: 4 }} />
      </TableCell>
    </Hidden>
    <Hidden xsDown>
      <TableCell align="center">
        <Skeleton variant="circle" width={32} height={32} style={{ padding: 4 }} />
      </TableCell>
    </Hidden>
    <TableCell align="center">
      <Skeleton variant="circle" width={32} height={32} style={{ padding: 4 }} />
    </TableCell>
  </TableRow>
);

const TransmissionCheckItem = ({ deviceData }) => {
  const { t } = useTranslation();

  if (!deviceData.connectivity) {
    return (
      <Tooltip title={t("connection.not_module")}>
        <WifiOff style={{ color: grey[300] }} />
      </Tooltip>
    );
  } else {
    const transmission = !!deviceData?.transmission_mode?.transmit_frequency ? TRASNMISSION_FREC[deviceData?.transmission_mode?.transmit_frequency] : TRASNMISSION_FREC[0];
    const deviceLasttransmission = deviceData?.status?.device_status?.updateDate;
    if (!moment().subtract(transmission?.gap, transmission?.type).isAfter(moment(deviceLasttransmission))) {
      return (
        <Tooltip title={t("connection.ready")}>
          <Wifi color="secondary" />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t(`connection.not_ready_${transmission.type}`, { gap: transmission.gap })}>
          <WifiOff style={{ color: red[300] }} />
        </Tooltip>
      );
    }
  }
};

const StatusIcon = ({ row, battery }) => {
  const { status, connectivity } = row;
  const outOfSync = moment().diff(status?.updateDate, "months") >= (connectivity ? 3 : 12);

  if (!!status) {
    const state = getDeviceStatus(status, outOfSync, battery).statusResult;
    if (state === 0) return <Check style={{ color: green[400] }} />;
    else if (state === 1) return <Warning style={{ color: orange[400] }} />;
    else return <Error style={{ color: red[400] }} />;
  } else {
    return <Check style={{ color: green[400] }} />;
  }
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(DevicesPage);

const useStyles = makeStyles((theme) => ({
  Fab: {
    color: blueGrey[50],
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    borderRadius: 19,
    textTransform: "none",
  },
  table: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(7),
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

var devicesBack = [];
