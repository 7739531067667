import { Button, Chip, Grid, Paper, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import { LocalPlayOutlined } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";

const Licenses = ({ isLoading, serialNumber }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} item>
      <Grid container item xs={12}>
        <Grid item xs={12} container alignItems="center">
          {isLoading ? (
            <Skeleton
              variant="circle"
              style={{
                marginRight: 8,
              }}
              width={30}
              height={30}
            />
          ) : (
            <LocalPlayOutlined
              color="secondary"
              style={{
                marginRight: 8,
                height: 30,
                width: 30,
              }}
            />
          )}

          <Typography color="secondary" style={{ fontWeight: 500, fontSize: 22, lineHeight: "30px" }}>
            {isLoading ? <Skeleton width={250} /> : "Licencias"}
          </Typography>
          <Typography color="textSecondary" style={{ marginTop: 9, marginBottom: 16 }}>
            {t("device.licence.HowToEnable", { sn: serialNumber })}
          </Typography>
        </Grid>
      </Grid>
      {[
        { type: "NFC Reader", active: true, permanent: false, expiration: "25/05/2024" },
        { type: "RDN Plus (Visionado de episodios)", active: false, permanent: true, expiration: "" },
      ].map((e, i) => (
        <Grid item xs={12} sm={6} key={i}>
          <Paper variant="outlined" className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" color="secondary">
                  {isLoading ? <Skeleton width={250} /> : e.type}
                  {!isLoading && e.type == "NFC Reader" && (
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="small"
                      label={t("sources.download.App")}
                      style={{ marginLeft: 9 }}
                      clickable
                      onClick={() => console.log("descargar el nfc reader")}
                    />
                  )}
                </Typography>

                {e.active ? (
                  <Typography variant="subtitle1" color="textSecondary">
                    {isLoading ? <Skeleton width={220} /> : `Activa ${e.permanent ? " · Permanente" : ""}`}
                  </Typography>
                ) : (
                  <Tooltip placement="top-start" title={<Typography>Actualice el equipo y le activaremos la licencia</Typography>}>
                    <Typography variant="subtitle1" color="error">
                      {isLoading ? <Skeleton width={220} /> : "No activada"}
                    </Typography>
                  </Tooltip>
                )}

                <Typography variant="body2" style={{ marginTop: 12 }}>
                  {isLoading ? (
                    <>
                      <Skeleton />
                      <Skeleton />
                    </>
                  ) : e.type === "NFC Reader" ? (
                    "Aplicación movil para leer y escribir la configuración del dispositivo mediante NFC"
                  ) : (
                    "Activa la pestaña de episodios para poder visualizar los episodios que se han sincronizado"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Licenses;

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 16,
    padding: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(2),

      boxShadow: "0 0",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
}));
