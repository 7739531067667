import { Badge, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Typography, withStyles } from "@material-ui/core";
import BexenCardioLogoColorH from "../../assets/images/0_3_BEXEN_Cardio_Horiz.png";
import { Devices, GroupWork, People, School, ShoppingBasketOutlined, Folder, NewReleases } from "@material-ui/icons";

import React, { useEffect, useState } from "react";
import * as ROUTES from "../../constants/routes";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AuthDrawer = ({ user, setanchor, currentLang }) => {
  const [open, setopen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const customRoute = (endPoint) => {
    let url = "";
    const l = location.pathname.split("/");
    url = l.length >= 4 ? ROUTES.PROGRAMS + `/${l[2]}` : "";
    url += l.length == 6 ? ROUTES.GROUPS + `/${l[4]}` + endPoint : endPoint;
    window.scrollTo(0, 0);
    history.push(url);
  };

  const goToGroups = () => {
    let url = "";
    const l = location.pathname.split("/");
    if (!(l.length >= 4) && user.program.length == 1) {
      url = ROUTES.PROGRAMS + `/${user.program[0].id}` + ROUTES.GROUPS;
    } else {
      url = l.length >= 4 ? ROUTES.PROGRAMS + `/${l[2]}` + ROUTES.GROUPS : ROUTES.GROUPS;
    }
    window.scrollTo(0, 0);
    history.push(url);
  };

  var lastPathItem = location.pathname.split("/").pop();

  return (
    <div>
      <List
        onClick={() => setanchor(false)}
        style={{ width: 250 }}
        subheader={
          <ListSubheader component="div" id="user-subheader">
            {t("navigation.management")}
          </ListSubheader>
        }
      >
        {user.programas.length > 1 && (
          <ListItem
            button
            key={1}
            selected={ROUTES.PROGRAMS.includes(lastPathItem)}
            onClick={() => {
              window.scrollTo(0, 0);
              history.push(ROUTES.PROGRAMS);
            }}
          >
            <ListItemIcon>
              <NavigatordBadge badgeContent={user?.total_sources?.num_programs} color="primary">
                <School color="primary" />
              </NavigatordBadge>
            </ListItemIcon>
            <ListItemText
              primary={t("navigation.programs")}
              primaryTypographyProps={{
                color: ROUTES.PROGRAMS.includes(lastPathItem) ? "primary" : "textPrimary",
              }}
            />
          </ListItem>
        )}

        <ListItem button key={2} selected={ROUTES.GROUPS.includes(lastPathItem)} onClick={goToGroups}>
          <ListItemIcon>
            <NavigatordBadge badgeContent={user?.total_sources?.num_groups} color="primary">
              <GroupWork color="primary" />
            </NavigatordBadge>
          </ListItemIcon>
          <ListItemText
            primary={t("navigation.groups")}
            primaryTypographyProps={{
              color: ROUTES.GROUPS.includes(lastPathItem) ? "primary" : "textPrimary",
            }}
          />
        </ListItem>
      </List>
      <List
        onClick={() => setanchor(false)}
        style={{ width: 250 }}
        subheader={
          <ListSubheader component="div" id="user-subheader">
            {t("navigation.elements")}
          </ListSubheader>
        }
      >
        <ListItem
          button
          key={3}
          selected={location.pathname.includes(ROUTES.DEVICES)}
          onClick={() => {
            customRoute(ROUTES.DEVICES);
          }}
        >
          <ListItemIcon>
            <NavigatordBadge badgeContent={user?.total_sources?.num_devices} color="primary">
              <Devices color="primary" />
            </NavigatordBadge>
          </ListItemIcon>

          <ListItemText
            primary={t("navigation.devices")}
            primaryTypographyProps={{
              color: location.pathname.includes(ROUTES.DEVICES) ? "primary" : "textPrimary",
            }}
          />
        </ListItem>
        {false && (
          <ListItem
            button
            key={4}
            selected={location.pathname.includes(ROUTES.ACCESSORIES)}
            onClick={() => {
              customRoute(ROUTES.ACCESSORIES);
            }}
          >
            <ListItemIcon>
              <NavigatordBadge badgeContent={user?.total_sources?.num_accessories} showZero color="primary">
                <ShoppingBasketOutlined color="primary" />
              </NavigatordBadge>
            </ListItemIcon>

            <ListItemText
              primary={t("navigation.accessories")}
              primaryTypographyProps={{
                color: location.pathname.includes(ROUTES.ACCESSORIES) ? "primary" : "textPrimary",
              }}
            />
          </ListItem>
        )}

        <ListItem
          button
          key={5}
          selected={location.pathname.includes(ROUTES.USERS)}
          onClick={() => {
            customRoute(ROUTES.USERS);
          }}
        >
          <ListItemIcon>
            <NavigatordBadge badgeContent={user?.total_sources?.num_users} showZero color="primary">
              <People color="primary" />
            </NavigatordBadge>
          </ListItemIcon>

          <ListItemText
            primary={t("navigation.users")}
            primaryTypographyProps={{
              color: location.pathname.includes(ROUTES.USERS) ? "primary" : "textPrimary",
            }}
          />
        </ListItem>
      </List>
      <List
        onClick={() => setanchor(false)}
        style={{ width: 250 }}
        subheader={
          <ListSubheader component="div" id="user-subheader">
            {t("navigation.support")}
          </ListSubheader>
        }
      >
        <ListItem
          button
          key={6}
          selected={location.pathname.includes(ROUTES.SOURCES)}
          onClick={() => {
            history.push(ROUTES.SOURCES);
            window.scrollTo(0, 0);
          }}
        >
          <ListItemIcon>
            <NavigatordBadge badgeContent={user?.total_sources?.num_resources} showZero color="primary">
              <Folder color="primary" />
            </NavigatordBadge>
          </ListItemIcon>

          <ListItemText
            primary={t("navigation.sources")}
            primaryTypographyProps={{
              color: location.pathname.includes(ROUTES.SOURCES) ? "primary" : "textPrimary",
            }}
          />
        </ListItem>

        {/*  <ListItem
          button
          key={6}
          selected={location.pathname.includes(ROUTES.RELEASE_NOTES)}
          onClick={() => {
            history.push(ROUTES.RELEASE_NOTES);
            window.scrollTo(0, 0);
          }}
        >
          <ListItemIcon>
            <NavigatordBadge color="primary">
              <NewReleases color="primary" />
            </NavigatordBadge>
          </ListItemIcon>

          <ListItemText
            primary={t("navigation.releasesNotes")}
            primaryTypographyProps={{
              color: location.pathname.includes(ROUTES.RELEASE_NOTES) ? "primary" : "textPrimary",
            }}
          />
        </ListItem>*/}
      </List>

      <Typography variant="body1" style={{ position: "absolute", bottom: 10, right: 20 }}>
        V 2.0.0.10
      </Typography>
    </div>
  );
};

export default AuthDrawer;

const NavigatordBadge = withStyles((theme) => ({
  badge: {
    right: -4,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);
