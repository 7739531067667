import {
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Avatar,
  makeStyles,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  LinearProgress,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Snackbar,
  DialogActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { green, purple } from "@material-ui/core/colors";
import { ArrowBack, GroupAdd, Person } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import { withAuthorization } from "../components/Session";
import * as DATANETAPI from "../components/DataNet/DataNet";
import * as DATANETAUTH from "../components/DataNet/DataNetAuth";
import LoadingPage from "../components/LoadingPage";
import { useParams } from "react-router-dom";
import { red } from "@material-ui/core/colors";
import RequestPasswordButton from "../components/RequestPasswordButton";
import ErrorDialog from "../components/ErrorDialog";

const INI_ERROR = {
  name: true,
  last_name: true,
  telephone: true,
  groups: true,
  programs: true,
};

const UserPage = () => {
  /** revisar que los grupos y los programas no esten vacios */
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { uid } = useParams();
  let isgroupAsigned = false;

  const [userForm, setUserForm] = useState([]);
  const [errorForm, setErrorForm] = useState(INI_ERROR);
  const [isValid, setIsValid] = useState(true);
  const [isUserForm, setIsUserForm] = useState(false);
  const [passForm, setPassForm] = useState([]);
  const [userPrograms, setUserPrograms] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [posiblePrograms, setPosiblePrograms] = useState([]);
  const [areProgramsLoad, setAreProgramsLoad] = useState(false);
  const [groups, setGroups] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [isDataModified, setIsDataModified] = useState(false);
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");
  const [isPasswordCorrectSend, setIsPasswordCorrectSend] = useState(false);

  useEffect(() => {
    /** hook de efecto para comprobar si se ha cambiado el formulario */
    setIsDataModified(true);
  }, [groups, userForm, programs]);

  const checkGroup = (event) => {
    setGroups({ ...groups, [event.target.name]: event.target.checked });
  };

  const checkProgram = (event) => {
    setPrograms({
      ...programs,
      [event.target.name]: {
        ...programs[event.target.name],
        checked: event.target.checked,
      },
    });
  };

  useEffect(() => {
    setIsValid(Object.keys(errorForm).filter((x) => errorForm[x] === false).length === 0);
  }, [errorForm]);

  useEffect(() => {
    let userNewPrograms = [];
    Object.keys(programs).map((p) => {
      if (programs[p].checked) {
        userNewPrograms.push({ id: p, name: programs[p].name });
      } else {
        posiblePrograms.map((pp) => {
          pp.id == p &&
            pp.groups.map((pg) => {
              groups[pg.id] = false;
            });
        });
      }
    });
    setUserPrograms(userNewPrograms);
    setErrorForm({ ...errorForm, progrmas: userNewPrograms.length > 0 });
    setErrorForm({
      ...errorForm,
      groups: Object.keys(groups).filter((x) => x === true).length > 0,
    });
  }, [programs]);

  useEffect(() => {
    var groups_temp = [];

    Object.keys(groups).forEach((x) => {
      if (groups[x]) {
        var p = posiblePrograms.filter((p) => p.groups.filter((g) => g.id === x).length > 0)[0];
        if (p !== undefined) {
          var group = p.groups.filter((g) => g.id === x)[0];
          groups_temp.push({
            id: x,
            name_id: group.name,
            program_id: group.program_id,
          });
        }
      }
    });
    userForm.groups = groups_temp;
    setErrorForm({ ...errorForm, groups: groups_temp.length > 0 });
  }, [groups]);

  useEffect(() => {
    if (isUserForm) {
      DATANETAPI.currentUser().then((snap) => {
        if (snap.status < 0) {
          setConnectionErrorMsg(t(`error.connection_${snap.status}`));
          setPosiblePrograms([]);
          setAreProgramsLoad(false);
          setIsDataModified(false);
        } else {
          if (snap.programas !== undefined) {
            setPosiblePrograms(snap.programas);
            var a = initPrograms(snap.programas, userForm.program);
            setPrograms(a);
            var b = initGroups(snap.programas, userForm.groups);
            setGroups(b);
            setAreProgramsLoad(true);
            setIsDataModified(false);
          } else {
            setPosiblePrograms([]);
            setAreProgramsLoad(false);
            setIsDataModified(false);
          }
        }
      });
    }
  }, [isUserForm]);

  useEffect(() => {
    if (uid !== undefined) {
      DATANETAPI.getUser(uid).then((snap) => {
        if (snap.data !== undefined) {
          setUserForm(snap.data);
          setIsUserForm(true);
          setIsDataModified(false);
        } else {
          setIsUserForm(false);
          setUserForm([]);
          setIsDataModified(false);
        }
      });
    }
  }, []);

  const handleSubmit = () => {
    DATANETAPI.editUser(userForm, uid).then((snap) => {
      if (snap.status < 0) {
        setConnectionErrorMsg(t(`error.connection_${snap.status}`));
      } else {
        if (snap != undefined) {
          setOpenAlert(true);
          if (snap.status === 1) {
            setIsError(false);
          } else {
            setIsError(true);
          }
        }
      }
    });
  };

  const canChangePrograms = (userroles = [{}]) => {
    return userroles.some((r) => r.id === "f4a134d5-bbd7-4a49-95b2-72f6e1096f41");
  };

  return (
    <Container>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Button
            style={{ marginTop: 24, fontSize: 30, fontSize: 16, textTransform: "none" }}
            color="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            <ArrowBack />
            {" " + t("common.goBack")}
          </Button>
          <PageHeader title={""} />
        </Grid>
      </Grid>
      {!isUserForm && <LoadingPage />}
      {isUserForm && <LoadingPage /> && (
        <>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} lg={10} xl={9}>
              <Paper style={{ padding: 32 }} variant="outlined">
                <Grid container alignItems="row" spacing={4}>
                  <Grid item xs={12} sm={3} className={classes.avatarCard} container justify="center" alignItems="center">
                    <Avatar className={classes.large} color="white">
                      <Person className={classes.userMainIcon} color="secondary" />
                    </Avatar>
                  </Grid>
                  <Grid item xs={12} sm={9} container spacing={3} style={{ paddingLeft: 19 }}>
                    <Grid item xs={12} container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h5" color="secondary" style={{ fontWeight: 600 }}>
                          {userForm.name + " " + userForm.last_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                          {userForm.email}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body1">{t("user.info.telephone")}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body1" color="textSecondary">
                          {userForm.telephone}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1">{t("users.table.role")}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body1" color="textSecondary">
                          {t(canChangePrograms(userForm?.roles) ? "mantenance_rol" : "standard_rol")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={10} xl={9}>
              <Paper style={{ padding: 18, marginTop: 14 }} variant="outlined">
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: 18,
                    lineHeight: "30px",
                    marginBottom: 18,
                  }}
                  color="secondary"
                >
                  {t("user.info.passwordChange.title")}
                </Typography>
                <Grid container xs={12} spacing={1} alignItems="center">
                  <Grid item xs={8}>
                    <Typography style={{ color: isPasswordCorrectSend && green[500] }}>
                      {isPasswordCorrectSend
                        ? t("pasword.reset.request.correct")
                        : t("requestPasswordChangeText", {
                            email: userForm.name,
                          })}
                    </Typography>
                  </Grid>
                  <Grid container item xs={4} justifyContent="flex-end">
                    <RequestPasswordButton email={userForm.email} iscorrectSend={setIsPasswordCorrectSend} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={10} xl={9}>
              <Paper style={{ padding: 18, marginTop: 14 }} variant="outlined">
                {!areProgramsLoad && <LinearProgress />}

                {areProgramsLoad && (
                  <>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: 18,
                        lineHeight: "30px",
                        marginBottom: 18,
                      }}
                      color="secondary"
                    >
                      {t("user.info.edit.title") + " " + userForm.name}
                    </Typography>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12}>
                        <Typography
                          style={{
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: "20px",
                            marginBottom: 14,
                          }}
                          color="textSecondary"
                        >
                          {t("user.info.edit.basic.title")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            name="userForm[name]"
                            defaultValue={userForm.name}
                            onChange={(e) => setUserForm({ ...userForm, name: e.target.value })}
                            onBlur={(e) =>
                              setErrorForm({
                                ...errorForm,
                                name: e.target.value !== "",
                              })
                            }
                            label={t("user.info.edit.name")}
                            placeholder={t("user.info.edit.name.placeholder")}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            name="userForm[last_name]"
                            defaultValue={userForm.last_name}
                            onChange={(e) =>
                              setUserForm({
                                ...userForm,
                                last_name: e.target.value,
                              })
                            }
                            onBlur={(e) =>
                              setErrorForm({
                                ...errorForm,
                                last_name: e.target.value !== "",
                              })
                            }
                            label={t("user.info.edit.last_name")}
                            placeholder={t("user.info.edit.last_name.placeholder")}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                          <TextField
                            name="userForm[telephone]"
                            defaultValue={userForm.telephone}
                            onChange={(e) =>
                              setUserForm({
                                ...userForm,
                                telephone: e.target.value,
                              })
                            }
                            onBlur={(e) =>
                              setErrorForm({
                                ...errorForm,
                                telephone: e.target.value !== "",
                              })
                            }
                            label={t("user.info.edit.phone")}
                            placeholder={t("user.info.edit.phone.placeholder")}
                            variant="outlined"
                            type="tel"
                            pattern="[0-9]*"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          style={{
                            marginTop: 24,
                            fontWeight: 400,
                            fontSize: 16,

                            lineHeight: "20px",
                          }}
                          color="textSecondary"
                        >
                          {t("user.info.edit.programs.title")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {
                          <FormControl component="fieldset">
                            <FormGroup row>
                              {posiblePrograms.map((g, i) => (
                                <FormControlLabel
                                  control={<Checkbox name={g.id} checked={programs[g.id].checked} onChange={checkProgram} />}
                                  label={g.name}
                                  disabled={canChangePrograms(userForm?.roles)}
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                        }
                      </Grid>

                      {!canChangePrograms(userForm?.roles) && (
                        <>
                          <Grid item xs={12}>
                            <Typography
                              style={{
                                marginTop: 24,
                                fontWeight: 400,
                                fontSize: 16,
                                lineHeight: "20px",
                              }}
                              color="textSecondary"
                            >
                              {t("user.info.edit.groups.title")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl component="fieldset">
                              <FormGroup row>
                                {false && t("user.info.edit.selectAtLeastOneProgram")}
                                {posiblePrograms.map((p, i) => {
                                  if (hasThis(userPrograms, p)) {
                                    return p.groups.map((g) => {
                                      return <FormControlLabel control={<Checkbox name={g.id} disabled checked={groups[g.id]} onChange={checkGroup} />} label={g.name} />;
                                    });
                                  }
                                })}
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        </>
                      )}

                      <Grid container item xs={12} justify="flex-end">
                        <Button variant="contained" color="primary" disabled={!isValid || !isDataModified} onClick={() => handleSubmit()}>
                          {t("user.info.edit.editButton")}
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </>
      )}

      <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />

      <Dialog open={openAlert}>
        <DialogTitle id="alert-dialog-title">{isError ? t("devices.setUp.dialog.title.error") : t("devices.setUp.dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{isError ? t("devices.setUp.dialog.description.error") : t("devices.setUp.dialog.description")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAlert(false);
            }}
            variant="text"
            color="primary"
            autoFocus
          >
            {t("devices.setUp.dialog.agree")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const hasThis = (elements, element) => {
  let has = false;
  elements.forEach((g) => {
    if (g.id === element.id) {
      has = true;
    }
  });
  return has;
};

const initPrograms = (posible, user) => {
  let checked = {};
  posible.map((p, i) => {
    checked[p.id] = { checked: hasThis(user, p), name: p.name, id: p.id };
  });
  return checked;
};

const initGroups = (posible, user) => {
  let checked = {};
  posible.map((p, i) => {
    p.groups.map((g) => (checked[g.id] = hasThis(user, g)));
  });
  return checked;
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(UserPage);

const useStyles = makeStyles((theme) => ({
  avatarCard: { background: "#f7f8fa", borderRadius: 9 },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    backgroundColor: "#fff",
  },
  userMainIcon: {
    fontSize: theme.spacing(18),
  },
}));

const posibleProgramsBack = [
  {
    id: "1",
    name: "Ermua",
    groups: [
      { id: "11", name: "Ermua centro" },
      { id: "12", name: "Ermua Sur" },
      { id: "13", name: "Ermua Norte" },
    ],
  },
  {
    id: "2",
    name: "UPV/EHU",
    groups: [
      { id: "21", name: "UPV/EHU Bilbao" },
      { id: "22", name: "UPV/EHU Donosti" },
      { id: "23", name: "UPV/EHU Vitoria" },
    ],
  },
];

const roles = [
  {
    id: "046cdcd2-4bca-411e-bc52-ceed874401e5",
    name_id: "PROGRAM ADMINISTRATOR",
  },
  /*  {id : "046cdcd2-4bca-411e-bc52-ceed874401e5", name_id : "PROGRAM ADMINISTRATOR"},
  {id : "18649469-6482-4646-aec5-e78c5cb64687", name_id : "PROGRAM COORDINATOR"},
  {id : "1ca88a60-f755-4508-abda-58c449ffeb0e", name_id : "GROUP ADMINISTRATOR"},
  {id : "21ebfeed-43d9-458e-b0c5-485dfbc353bb", name_id : "TRAINING COORDINATOR"},
  {id : "32501649-0217-4590-b64b-c80bf1798faf", name_id : "GLOBAL ADMINISTRATOR"},
  {id : "58b2b660-c2d6-42f0-b0b0-ae97968ba1a3", name_id : "GROUP COORDINATOR"},
  {id : "8322d0e1-ca01-4d1c-8034-e213cd840e3b", name_id : "MASTER ADMINISTRATOR"},
  {id : "84da3594-87bb-4541-8eb6-ef940551db04", name_id : "AED INSPECTION COORDINATOR"},
  {id : "a5c2bb91-ba6a-4f2c-b572-cab977ef931d", name_id : "AED INSPECTOR"},
  {id : "e639c76b-9709-4ad8-9121-5298d9cfe3c7", name_id : "TRAINED RESCUERS"}*/
];
