import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  colors,
  Collapse,
  IconButton,
  Fade,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@material-ui/core";
import { GroupWorkOutlined, LocationCityOutlined, BusinessOutlined, LocationOnOutlined, DescriptionOutlined, PeopleAltOutlined, ExpandLess, ExpandMore } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import * as API_KEYS from "../../constants/apiKeys";
import { LocationOn } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as DATANETAPI from "../DataNet/DataNet";
import ButtonLoader from "../ButtonLoader";
import getDeviceComunications from "../../functions/getDeviceComunications";
import * as COMS from "../../constants/comsTypes";

const INITAL_ERROR_EDIT_STATE = {
  name: false,
  def: false,
  address: false,
  require_rescuers: false,
  location_name: false,
  location: false,
  location_marker: false,
};

const INITAL_ERROR_ADD_STATE = {
  name: false,
  def: false,
  address: false,
  require_rescuers: false,
  location_name: false,
  location_marker: false,
};

const INITAL_STATE = {
  name: "",
  def: "",
  address: "",
  type: [],
  require_rescuers: 0,
  location: {
    name: "",
    latitude: 43.1854194,
    longitude: -2.5099712,
  },
  coordinators: [],
};

const AddGroupModal = (props) => {
  const { handleClose, currentposition, groupData, currentUser, deviceComs, isForNewDevice } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [isNew, setIsNew] = useState(!groupData);
  const [group, setGroup] = useState(!!groupData ? groupData : INITAL_STATE);
  const [errorForm, setErrorForm] = useState(!groupData ? INITAL_ERROR_ADD_STATE : INITAL_ERROR_EDIT_STATE);
  const [errorId, setErrorId] = useState(0);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [isValid, setIsvalid] = useState(false);
  const { groupId, programId } = useParams();
  const [isFormModified, setIsFormModified] = useState(false);
  const [newGroupId, setNewGroupId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [nameErrorText, setNameErrorText] = useState("");
  const [locationExpanded, setLocationExpanded] = useState(false);
  const [comunicationsType, setComunicationsType] = useState({
    Standalone: groupData?.comsTypes?.includes(COMS.STANDALONE) ?? false,
    Wifi: groupData?.comsTypes?.includes(COMS.WIFI) ?? false,
    Sigfox: groupData?.comsTypes?.includes(COMS.SIGFOX) ?? false,
  });
  const [devicesCommonComsAvailable, setDevicesCommonComsAvailable] = useState([]);

  useEffect(() => {
    if (isNew) {
      if (currentUser?.groups.filter((g) => g.name == group.name && g.program_id == programId).length > 0) {
        setErrorForm({ ...errorForm, name: true });
        setNameErrorText(t("group.name.sameName"));
      } else {
        if (errorForm.name) {
          handleNameChange(group.name);
        } else {
          errorForm.name = false;
        }
      }
    } else {
      if (currentUser?.groups.filter((g) => g.name == group.name && g.program_id == programId && groupData.groupid != g.id).length > 0) {
        setErrorForm({ ...errorForm, name: true });
        setNameErrorText(t("group.name.sameName"));
      } else {
        if (errorForm.name) {
          handleNameChange(group.name);
        } else {
          errorForm.name = false;
        }
      }
    }
  }, [group.name]);

  useEffect(() => {
    // se neceista saber cual es la información del dispositivo que está obligando a crear el grupo para que el grupo tenga esas comunicaciones y no se puedan modificar
    if (!!deviceComs && isForNewDevice) {
      setComunicationsType({
        Standalone: deviceComs.includes(COMS.STANDALONE),
        Wifi: deviceComs.includes(COMS.WIFI),
        Sigfox: deviceComs.includes(COMS.SIGFOX),
      });
    }
  }, [deviceComs]);

  useEffect(() => {
   // console.log(comunicationsType);
    setIsvalid(!Object.keys(errorForm).some((x) => errorForm[x]) && group.name !== "" && group.def !== "" && Object.values(comunicationsType).some((v) => v));
  }, [errorForm, comunicationsType]);

  const handleCloseError = () => {
    setOpenError(false);
    if (modalSuccess) {
      // se manda el id del grupo creado, para que cuando se está creando un equipo se pueda asiciar en luneo equipo al grupo.
      handleClose(group?.id);
    }
  };

  const handleComsType = (e) => {
    setComunicationsType({ ...comunicationsType, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    if (!!groupData) {
      delete groupData["med_agency"];
    }
  }, []);

  useEffect(() => {
    (group != groupData ||
      comunicationsType.Standalone !== groupData?.comsTypes?.includes(COMS.STANDALONE) ||
      comunicationsType.Wifi !== groupData?.comsTypes?.includes(COMS.WIFI) ||
      comunicationsType.Sigfox !== groupData?.comsTypes?.includes(COMS.SIGFOX)) &&
      setIsFormModified(true);
  }, [group, comunicationsType]);

  useEffect(() => {
    // cargamos los datos de los equipos para saber si es posible cambiar el tipo de comunicación del grupo
    if (!isNew) {
     // console.log("tengo estos datos", group);
      if (!!!group.groupid || !!!group.programid) {
        /* Buscamos los ids en los datos del usuario */
      }
      const groupId = group.groupid;
      const programId = group.programid;
      DATANETAPI.getDevices(programId, groupId).then((snap) => {
        if (snap?.status === 1) {
          if (snap.data.length > 0) {
            //si hay datos
          //  console.log("---------", snap.data);
            const groupDevices = snap.data.map((g) => ({
              ...g,
              comsTypes: getDeviceComunications(g?.options),
            }));
           // console.log("datos de los equipos -- ", groupDevices);

            const commonDeviceComs = groupDevices.reduce((acc, current) => acc.filter((t) => current?.comsTypes?.includes(t)), groupDevices[0]?.comsTypes); // tipo de comunicaciones que comparten todos los dispositivos
            if (groupDevices.length === 0) {
              setDevicesCommonComsAvailable([COMS.SIGFOX, COMS.STANDALONE, COMS.WIFI]);
            } else {
              setDevicesCommonComsAvailable(commonDeviceComs);
            }
          } else {
            // el grupo puede cambiar a cualquiera de los tipos porque no tiene equipos dentro
            setDevicesCommonComsAvailable([COMS.SIGFOX, COMS.STANDALONE, COMS.WIFI]);
          }
        } else {
          /** no ha ido bien la petición */
        }
      });
    } else {
      /* Se inicializan todas las opciones de comunicaciones dipponibles */
      setDevicesCommonComsAvailable([COMS.SIGFOX, COMS.STANDALONE, COMS.WIFI]);
    }
  }, []);

  const mapClickHandler = ({ x, y, lat, lng, event }) => {
    setGroup({
      ...group,
      location: {
        ...group.location,
        latitude: lat,
        longitude: lng,
      },
    });
    setErrorForm({ ...errorForm, location_marker: false });
    setIsFormModified(true);
  };

  const createGroup = (e) => {
    setIsRequestLoading(true);
    e.preventDefault();
    group.address = group.address === "" ? "--" : group.address;
    group.location.name = group.location.name === "" ? "--" : group.location.name;
    group.comsTypes = Object.entries(comunicationsType)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);

    if (isNew) {
      if (programId !== undefined) {
        group.programid = programId;
      }
     // console.log("datos del grupo a crear", group);
      DATANETAPI.createGroup(group).then((snap) => {
        processHttpResponse(snap, false);
      });
    } else {
      if (programId !== undefined) {
        group.programid = programId;
      }
      if (groupId !== undefined) {
        group.groupid = groupId;
      }
      DATANETAPI.editGroup(group).then((snap) => {
        processHttpResponse(snap, false);
      });
    }
  };

  const deleteGroup = () => {
    setIsDeleting(true);
    DATANETAPI.deleteGroup(group.groupid).then((snap) => {
      processHttpResponse(snap, true);
      setIsDeleting(false);
    });
  };

  const processHttpResponse = (snap, is_delete) => {
    if (snap.status !== undefined) {
      setIsDelete(is_delete);
      // request finished
      if (snap.status === 1) {
        // SUCCESS
        !!snap.data && setNewGroupId(snap?.data?.groupid);
        !snap.data && setNewGroupId(group?.groupid);

        /* Si el grupo es creado al añadir un dispositivo, no mostrar el dialogo para evitar tener */
        if (isForNewDevice) {
          handleClose(snap?.data?.groupid);
        } else {
          setModalSuccess(true);
          setErrorId(1);
          setOpenError(true);
        }
      } else {
        // ERROR
        //console.log("error -1");
        setModalSuccess(false);
        setErrorId(snap.status);
        setOpenError(true);
      }
    } else {
      //error during request
      setModalSuccess(false);
      setErrorId(-1);
      setOpenError(true);
    }
    if (is_delete) setDeleteModal(false);
    setIsRequestLoading(false);
  };

  const renderErrorMessage = () => {
    if (errorId >= 0) {
      if (!modalSuccess) {
        let errorText;
        if (isNew) {
          errorText = "groups.new.err_";
        } else if (isDelete) {
          errorText = "groups.delete.error_";
        } else {
          errorText = "groups.edit.err_";
        }
        return t(errorText + errorId);
      } else {
        if (isDelete) return t("groups.delete.error_" + errorId);
        if (isNew) return t("groups.created_" + errorId, { name: group.name });
        return t("groups.err_" + errorId, { name: group.name });
      }
    } else {
      return t(`error.connection_${errorId}`);
    }
  };

  const handleNameChange = (value) => {
    const isError = !(value.length > 0 && value.length <= 50);
    setErrorForm({
      ...errorForm,
      name: isError,
    });
    isError && setNameErrorText(t("groups.error.name"));
  };

  return (
    <form onSubmit={createGroup}>
      <Grid container className={classes.formcontainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" color="secondary">
              {t("group_title")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <TextField
              id="outlined-basic"
              required
              label={t("common.name")}
              name="group[name]"
              value={group.name}
              onChange={(e) => {
                setGroup({ ...group, name: e.target.value });
                handleNameChange(e.target.value);
              }}
              error={errorForm.name}
              helperText={errorForm.name && nameErrorText}
              placeholder={t("common.name")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <GroupWorkOutlined color="primary" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={7}>
            <TextField
              fullWidth
              required
              id="outlined-textarea"
              label={t("common.description")}
              name="group[def]"
              defaultValue={group.def}
              onChange={(e) => {
                setGroup({ ...group, def: e.target.value });
                const length = e.target.value.length;
                setErrorForm({
                  ...errorForm,
                  def: !(length > 0 && length < 100),
                });
              }}
              error={errorForm.def}
              helperText={!errorForm.def ? "" : t("groups.error.def")}
              placeholder={t("groups.new.description.placeholder")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DescriptionOutlined color="primary" />
                  </InputAdornment>
                ),
              }}
              multiline
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend" color="secondary">
              {t("group_form_coms_label")}
            </FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  name={COMS.STANDALONE}
                  checked={comunicationsType.Standalone}
                  disabled={comunicationsType.Wifi || comunicationsType.Sigfox || !!deviceComs || !devicesCommonComsAvailable.includes(COMS.STANDALONE)}
                  onChange={handleComsType}
                />
              }
              label="Standalone"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name={COMS.WIFI}
                  checked={comunicationsType.Wifi}
                  disabled={comunicationsType.Standalone || !!deviceComs || !devicesCommonComsAvailable.includes(COMS.WIFI)}
                  onChange={handleComsType}
                />
              }
              label="Wifi"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name={COMS.SIGFOX}
                  checked={comunicationsType.Sigfox}
                  disabled={comunicationsType.Standalone || !!deviceComs || !devicesCommonComsAvailable.includes(COMS.SIGFOX)}
                  onChange={handleComsType}
                />
              }
              label="Sigfox"
            />
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Typography variant="h6" color="secondary">
              {t("common.location")}
            </Typography>
            <IconButton onClick={() => setLocationExpanded(!locationExpanded)}>{locationExpanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </Grid>
          <Collapse className={classes.expandedContainer} in={locationExpanded} timeout="auto" unmountOnExit style={{ width: "100%" }}>
            <Fade in={locationExpanded} timeout={250}>
              <Grid container spacing={3} style={{ background: "rgb(231 224 236 / 30%)", borderRadius: 19, padding: 12, marginTop: 9 }}>
                <Grid item xs={12}>
                  <FormLabel component="legend" color="secondary">
                    {t("grous_form_location")}
                  </FormLabel>
                </Grid>
                <Grid item xs={12} sm={5} md={6}>
                  <TextField
                    id="outlined-basic"
                    label={t("common.address")}
                    name="group[address]"
                    style={{ background: "white" }}
                    defaultValue={group.address}
                    onChange={(e) => {
                      const length = e.target.value.length;
                      setErrorForm({ ...errorForm, address: length > 50 });
                      setGroup({ ...group, address: e.target.value });
                    }}
                    error={errorForm.address}
                    helperText={!errorForm.address ? "" : t("groups.error.address")}
                    placeholder={t("common.address")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BusinessOutlined color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={7} md={6}>
                  <TextField
                    fullWidth
                    label={t("common.location")}
                    name="group[location][name]"
                    style={{ background: "white" }}
                    defaultValue={group.location.name}
                    onChange={(e) => {
                      const length = e.target.value.length;
                      setGroup({ ...group, location: { ...group.location, name: e.target.value } });
                      setErrorForm({ ...errorForm, location_name: length > 50 });
                    }}
                    error={errorForm.location_name}
                    helperText={!errorForm.location_name ? "" : t("groups.error.location")}
                    placeholder={t("groups.new.location.placeholder")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOnOutlined color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    height: "60vh",
                    border: `${errorForm.location_marker ? "1" : "1"}px solid`,
                    borderRadius: 9,
                    borderColor: colors.blueGrey[200],
                    padding: 0,
                    margin: 12,
                    overflow: "hidden",
                  }}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: API_KEYS.MAPS_API_KEY }}
                    defaultCenter={
                      !!currentposition
                        ? currentposition
                        : {
                            lat: group.location.latitude,
                            lng: group.location.longitude,
                          }
                    }
                    defaultZoom={15}
                    onClick={mapClickHandler}
                  >
                    {!errorForm.location_marker && (
                      <LocationOn
                        style={{
                          color: red[500],
                          position: "absolute",
                          transform: "translate(-50%, -95%)",
                        }}
                        lat={group.location.latitude}
                        lng={group.location.longitude}
                      />
                    )}
                  </GoogleMapReact>
                </Grid>
              </Grid>
            </Fade>
          </Collapse>
          {!isNew && (
            <Grid item xs={12}>
              <Card
                variant="outlined"
                style={{
                  padding: 32,
                  borderColor: red[500],
                  borderRadius: 8,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: 22,
                        lineHeight: "30px",
                        color: red[600],
                      }}
                    >
                      {t("actions")}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} container alignItems="center">
                    <Grid item xs={6}>
                      <Typography variant="body1">{t("groups.delete.action")}</Typography>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                      <Button variant="outlined" style={{ color: red[400], borderColor: red[400] }} onClick={() => setDeleteModal(true)}>
                        {t("delete")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            style={{
              alignSelf: "flex-end",
              // position: buttonPosition,
              //  bottom: buttonPosition == "absolute" && 19,
              // right: buttonPosition == "absolute" && 19,
              marginTop: 19,
            }}
          >
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <Button onClick={() => handleClose(false)} style={{ color: red[500] }}>
                  {t("groups.new.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" disabled={!(isValid && isFormModified) || isRequestLoading} type="submit">
                  {isNew ? t("groups.new.create") : t("groups.new.edit")}
                  {isRequestLoading && <ButtonLoader />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={openError} onClose={handleCloseError} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        {modalSuccess ? <DialogTitle id="alert-dialog-slide-title">{t("groups.success_title")}</DialogTitle> : <DialogTitle id="alert-dialog-slide-title">{t("groups.err_title")}</DialogTitle>}

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" aling="justify">
            {renderErrorMessage()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isDelete && (
            <>
              <Button color="primary" variant="contained" onClick={handleCloseError}>
                {t("common.ok")}
              </Button>
            </>
          )}
          {!isDelete && (
            <>
              <Button onClick={handleCloseError}>{t("common.ok")}</Button>
              <Button color="primary" variant="contained" onClick={() => history.push(`/programs/${programId}/groups/${newGroupId}/devices`)}>
                {t("viewDevices")}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog onClose={() => setDeleteModal(false)} open={deleteModal}>
        <DialogTitle style={{ color: red[400] }}>{t("groups.delete.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("groups.delete.body", { name: group.name })}</DialogContentText>
          <Grid container justify="center">
            <TextField label={t("common.name")} variant="outlined" value={groupName} fullWidth onChange={(e) => setGroupName(e.target.value)} style={{ marginTop: 6 }} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModal(false)}>{t("common.cancel")}</Button>
          <Button disabled={groupName !== group.name || isDeleting} style={{ color: groupName === group.name && !isDeleting && red[400] }} onClick={deleteGroup}>
            {t("delete")}
            {isDeleting && <ButtonLoader />}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default AddGroupModal;

const useStyles = makeStyles((theme) => ({
  formcontainer: {
    padding: 24,
  },
  expandedContainer: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));
